import orgServices from '../../../services/notisphere/organization'
import ProviderModal from './ProviderModal'
import { ORGANIZATION_STATUS} from '../../../misc/commons'
import ViewModal from '../../../components/part/shared/PreviewViewModal'
import { _findObj } from '../../../misc/genericFunctions'
import { AG_GridCustomizedTableHeaders_Key, txtFormatter, trimWhiteSpaces } from '../../../misc/tableHeaders'
export default {
    components: {
        ProviderModal,
        ViewModal
    },
    data() {
        return {
            columnDefs: null,
            gridOptions: {
                onFilterChanged: this.onFilterChanged,
                onSortChanged: this.onSortChanged,
            },
            search: {
                name: null,
                notisphere_id: null,
                add1: null,
                add2: null,
                city: null,
                state: null,
                zip: null,
                stt: null,
                sites: null
            },
            gridApi: null,
            columnApi: null,
            searchQuery: { pageNumber: 1, pageSize: 50, sort: null, colId: null },
            delay: 700,
            clicks: 0,
            timer: null,
            total: 0,
            viewModalKey: 0,
            items: [],
            header: [],
            defaultHeader: [],
            org_status: ORGANIZATION_STATUS,
            providerModalKey: 0,
            table_Connection_Data: this.$store.state.CustomizedHeaders.filter(s => s.userId == this.$store.state.user.id)[0],
            Notisphere_Provider_AgGrid: [
                { minWidth: 120, field: 'name',filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: false, headerName: 'Name', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
                { minWidth: 150, field: 'notisphere_id',filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: false,headerName: 'Notisphere ID', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab',  'filterMenuTab','columnsMenuTab'] },
                { minWidth: 90, field: 'notisphere_managed',filterParams: {values: params => {
                    orgServices.getNotisphereManagedFilters().then(resp => {
                        if (!resp.error) {
                            params.success(resp.data.d)
                        }
                    })
                }}, hide: false,headerName: 'Notisphere Managed', sortable: true, filter: 'agSetColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
                { minWidth: 120, field: 'add1',filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: false,headerName: 'Address 1', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
                { minWidth: 120, field: 'add2',filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces},hide: false, headerName: 'Address 2', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
                { minWidth: 90, field: 'city',filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces},hide: false, headerName: 'City', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
                { minWidth: 90, field: 'state',filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces},hide: false, headerName: 'State', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
                { minWidth: 80, field: 'zip',filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces},hide: false, headerName: 'Zip', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
                {
                    minWidth: 130,
                    field: 'document',
                    type: 'centerColumn',
                    headerName: 'Agreement',
                    hide: false,
                    sortable: true,
                    menuTabs: ['generalMenuTab', 'columnsMenuTab'],
                    cellRenderer: (params) => {
                        var docFrag = document.createDocumentFragment()
                        var that = this
                        for (let index = 0; index < params.value?.length; index++) {
                            let itemdoc = params.value[index]
                            let img = null
                            let viewclickDom = null
                            var ob = document.createElement('div')
                            if (itemdoc.mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || itemdoc.extension === 'docx' || itemdoc.extension === 'doc') {
                                img = require('@/assets/img/word.svg')
                            } else if (itemdoc.mimetype == 'application/pdf' || itemdoc.extension == 'pdf') {
                                img = require('@/assets/img/adobe.svg')
                            }
                            viewclickDom = 'viewclick_' + itemdoc.organization_document_id
                            ob.innerHTML = `<img class="m-1" src=${img} height="30" alt="" data-action=${viewclickDom}>
                            </img>`
                            docFrag.appendChild(ob)
                        }
                        return docFrag
                    }
                },
                { minWidth: 80, field: 'sso', filterParams: { values: params => {
                    orgServices.getSSOFilters().then(resp => {
                        if (!resp.error) {
                            params.success(resp.data.d)
                        }
                    })
                }}, hide: false, headerName: 'SSO', sortable: true, filter: 'agSetColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
                {
                    minWidth: 130, field: 'stt', type: 'centerColumn', filterParams: {  values: params => {
                        orgServices.getStatusFilters().then(resp => {
                            if (!resp.error) {
                                params.success(resp.data.d)
                            }
                        })
                    },valueFormatter: params => {
                        return _findObj(ORGANIZATION_STATUS, params.value)
                    } },hide: false, headerName: 'Status', sortable: true, filter: 'agSetColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab']
                    , cellRenderer: (params) => {
                        var ob = document.createElement('div')
                        ob.innerHTML = `<b-button  class="status-btn mx-auto">
                                            <span style="background: ${_findObj(ORGANIZATION_STATUS, params.value, 'value', 'color')}; width: 98px;
                                            font-size: 0.75rem;font-weight: 400;font-family: Rubik,sans-serif!important;" class="badge badge-secondary h6 py-2 px-3">
                                                ${_findObj(ORGANIZATION_STATUS, params.value)} 
                                            </span> 
                                        </b-button>`
                        return ob
                    }
                },
                { minWidth: 80, field: 'sites', type: 'rightColumn',filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces , filterOptions: ['equals'] },hide: false, headerName: 'Sites', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
                { minWidth: 100, field: 'actions',type: 'centerColumn',hide: false, headerName: 'Actions', cellRenderer: this.actionCellRenderer, suppressColumnsToolPanel: true
                },
            ]
        }
    },
    created() {
        this.rowModelType = 'serverSide'
    },
    beforeMount() {
        this.columnDefs = this.Notisphere_Provider_AgGrid
    },
    methods: {
        saveState(params) {
            this.table_Connection_Data.Notisphere_Login.Provider_Headers = params.columnApi.getColumnState()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
        },
        applyState(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var columnState = tableState?.Notisphere_Login?.Provider_Headers
            if (columnState) {
                params.columnApi.applyColumnState({ state: columnState, applyOrder: true })
            }
        },
        onFilterChanged(event) {
            this.saveState(event)
            this.fetchData(event)
        },
        onSortChanged(event) {
            this.saveState(event)
            this.fetchData(event)
        },
        findProviderAccountCSVReport() {
            orgServices.downloadProviderAccountCSVRecall({
                ...this.searchQuery,
                sortField: this.searchQuery.colId,
                sortDirection: this.searchQuery.sort
            }).then(resp => {
            })
        },
        findProviderAccountXLSXReport() {
            orgServices.downloadProviderAccountXLSXRecall({
                ...this.searchQuery,
                sortField: this.searchQuery.colId,
                sortDirection: this.searchQuery.sort
            }).then(resp => {
            })
        },
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type == 'INSERT') {
                this._showToast('Added successfully!')
            }
            this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
        },
        onCellClicked(params) {
            if (params.colDef.field == 'status') {
                this.addMoreRespClick(params.data)
                return
            }
            if (params.column.colId === 'document') {
                if(params.event.target.dataset.action != undefined){
                    if(params.event.target.dataset.action.includes('viewclick'))
                    {
                        for(let i=0; i <= params.data.document.length; i++)
                        {
                            if (params.event.target.dataset.action === 'viewclick_' + params.data.document[i].organization_document_id) {
                                return this.ViewClick(params.data.document[i])
                            }
                        }
                    }
                }

            }
            if (params.column.colId === 'actions') {
                if (params.event.target.dataset.action === 'edit') {
                    this.updateProvider(params.data)
                }
                if (params.event.target.dataset.action === 'delete') {
                    this.deletePendingProvider(params.data)
                }
                return
            }else{
                this.updateProvider(params.data)
            }
        },
        ViewClick(val) {
            let data = []
            orgServices.getProvider(val.organization_id).then(resp => {
                if (!resp.error) {
                    resp.data.d.document.forEach((x, i) => {
                        if (x.organization_document_id == val.organization_document_id) {
                            data = x
                        }
                    })
                    val = data
                    this.viewModalKey += 1
                    this.$nextTick(() => {
                        this.$refs.viewModal.forceRerenderInsert(val)
                    })
                }
            })


        },
        addMoreClick() {
            this.providerModalKey += 1
            this.$nextTick(() => {
                this.$refs.providerModal.forceRerenderInsert()
            })
        },
        updateProvider(payload, e = null) {
            this.providerModalKey += 1
            this.$nextTick(() => {
                this.$refs.providerModal.forceRerenderUpdate(payload.id)
            })
            if (e) {
                e.stopPropagation()
            }
        },
        fetchData(params) {
            this.applyState(params)
            let that = this
            that.gridApi = params.api
            that.columnApi = params.columnApi
            //params.api.sizeColumnsToFit()
            const datasource = {
                getRows(params) {
                    that.searchQuery.colId = params.request?.sortModel[0]?.colId
                    that.searchQuery.sort = params.request?.sortModel[0]?.sort
                    that.search.name = params.request?.filterModel?.name?.filter
                    that.search.add1 = params.request?.filterModel?.add1?.filter
                    that.search.add2 = params.request?.filterModel?.add2?.filter
                    that.search.city = params.request?.filterModel?.city?.filter
                    that.search.state = params.request?.filterModel?.state?.filter
                    that.search.zip = params.request?.filterModel?.zip?.filter
                    that.search.notisphere_id = params.request?.filterModel?.notisphere_id?.filter
                    that.search.sites = params.request?.filterModel?.sites?.filter
                    that.search.filterModel = that.gridApi.getFilterModel()
                    orgServices.findProviders({
                        ...that.searchQuery,
                        sortField: params.request?.sortModel[0]?.colId,
                        sortDirection: params.request?.sortModel[0]?.sort,
                        ...that.search
                    })
                        .then(resp => {
                            if (!resp.error) {
                                for (var i = 0; i < resp.data.d.c.length; i++) {

                                    if (resp.data.d.c[i].document.length == 0) {
                                        resp.data.d.c[i].document = null
                                    }
                                }
                                that.total = resp.data.d.t
                                if(that.total == 0){
                                    that.gridApi.showNoRowsOverlay()
                                }
                                else{
                                    that.gridApi.hideOverlay()
                                }
                                return resp.data.d.c
                            }
                        }).then(response => {

                            params.successCallback(response)
                        })
                        .catch(error => {
                            console.error(error)
                            params.failCallback()
                        })
                }
            }
            // register datasource with the grid
            params.api.setServerSideDatasource(datasource)
        },
        deletePendingProvider(item, e) {
            this._showConfirm('You are about to delete this organization, this cannot be undone.').then(confirm => {
                if (confirm) {
                    orgServices.deletePendingProvider(item.id).then(resp => {
                        if (!resp.error) {
                            this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
                            this._showToast('Deleted successfully!')
                        }
                    })
                }
            })
            //e.stopPropagation()
        },
        actionCellRenderer(params) {
            let eGui = document.createElement('div')
            let abc =''
            if(params.data.stt ==='PENDING')
            {
                abc = '<a class="dropdown-item delete"  data-action="delete"> <i class="far fa-trash-alt mr-2"> </i> Delete</a>'
            }
            eGui.innerHTML = `<div >
                    <i class="fas fa fa-ellipsis-v actionBtn" data-action="open" id="dropdownMenuButton" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false" ></i>
                    <div class="dropdown-menu"   aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item edit"  data-action="edit"> <i class="far fa-edit mr-2"></i> Edit</a>`+
                        abc +' </div> </div>  '
            return eGui
        },

    }
}